import { defineStore } from 'pinia'
import reportingQuery from '../apollo/queries/pages/reporting.gql'

export const useReportingStore = defineStore('reporting', {
  state: () => ({
    data: {}
  }),
  getters: {
    getData: (state) => {
      return state.data.reporting ? state.data.reporting.data.attributes : null
    }
  },
  actions: {
    async requestData() {
      if (Object.keys(this.data).length !== 0) return

      const { data } = await useAsyncQuery(reportingQuery) // eslint-disable-line no-undef

      if (data) this.data = data
    }
  }
})
